<template>
    <div>
        <v-row class="d-flex justify-center mt-5" v-if="show">
            <v-col sm="12" md="6">
                <div class="text-h5 primary--text font-weight-bold mb-4">
                    Get Loan
                </div>
                <v-card class="pa-10" style="border: 1px solid grey">
                    <div class="primary--text font-weight-bold mb-4">Loan Calculator</div>
                    <div class="mb-sm-2 mb-md-4">Get the amount of loan you are looking for using our instant loan calculator</div>
                    <v-form lazy-validation ref="form" v-model="isValid" @submit.prevent="SubmitForm">
                        <v-text-field
                                v-model="form.amount"
                                outlined
                                placeholder="enter amount here"
                                label="How Much You Wish To Borrow"
                                :rules="rules"
                        >
                            <template v-slot:prepend-inner>
                                <div class="primary--text font-weight-bold mr-3" style="margin-bottom: -6px;">&#8358;</div>
                            </template>
                        </v-text-field>
                        <div class="pb-sm-2 pb-md-4">
                            <v-subheader class="pl-0 mb-4 primary--text">
                                For How Long?
                            </v-subheader>
                            <v-slider
                                    :max="maxDuration"
                                    :min="minDuration"
                                    v-model="form.duration"
                                    thumb-label="always"
                            ></v-slider>
                        </div>
                        <v-text-field
                                class="my-sm-2 my-md-4"
                                v-model="form.description"
                                :rules="[v => !!v || 'reason is required']"
                                outlined
                                label="Purpose of Loan"
                                placeholder="enter purpose of loan"
                        />
                        <v-btn
                                color="primary"
                                block
                                class="py-md-7 py-sm-6"
                                type="submit"
                        >
                            <v-progress-circular indeterminate v-if="processing" color="secondary" width="4" class="mr-3"/>
                            Continue
                        </v-btn>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <alert-modal @dismiss="onCloseAlert" :show="showAlert">
            <div class="px-5 text-center">
                <div class="text-h5 primary--text font-weight-bold pb-5">Notice!!</div>
                <div class="primary--text">Your available Transave Loan limit is {{approvedAmount|floatNairaFormat}}</div>
                <v-btn color="secondary" class="my-4 py-6" tile block @click="onCloseAlert">
                    proceed
                </v-btn>
            </div>
        </alert-modal>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import AlertModal from "./AlertModal";

    export default {
        props: ["show"],
        components: {AlertModal },
        data: () =>({
            processing: false,
            isValid: true,
            value: Number,
            rules: [
                value => !!value || 'Amount is required',
                value => (value && value >= 2000) || 'Amount must be greater than ₦2000',
                value => (value && value <= 10000) || 'Amount must be less than ₦10000',
            ],
            minDuration: 5,
            maxDuration: 10,
            requestedAmount: '',
            approvedAmount: '',
            showAlert: false,
            form: new Form({
                amount: '',
                duration: 10,
                description: '',
            }),
        }),
        methods: {
            ...mapActions('loan', ['calculateLoan']),
            //...mapMutations('loan', ['storeLoanDetails']),
            SubmitForm() {
                if (this.$refs.form.validate()) {
                    const data = {
                        amount: this.form.amount,
                        owner_id: this.user.id,
                        duration: this.form.duration,
                        description: this.form.description,
                        role: 'user',
                    };
                    this.processing = true;
                    this.calculateLoan(data).then(res => {
                        this.$emit('onGetLoanDetails', res.data);
                        console.info('loan details', res.data);
                        //this.storeLoanDetails(res.data);
                        if (res.data.amount) {
                            if(this.form.amount > res.data.amount) {
                                this.requestedAmount = this.form.amount;
                                this.approvedAmount = res.data.amount;
                                this.showAlert = true;
                            }else {
                                this.onCloseAlert();
                            }
                        }else {
                            this.$toast.error('you have unpaid outstanding loan');
                            this.$emit('onFailedAmount');
                        }

                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    }).finally(() => {
                        this.processing = false;
                    });

                }else {
                    this.$toast.error('Validation error on page');
                }
            },
            onCloseAlert() {
                this.showAlert = false;
                this.$emit('onSubmit');
            }
        },
        computed: {
            ...mapGetters('auth', ['user']),
        },
        mounted() {
            sessionStorage.clear();
            this.form.reset();
            this.form.clear();
        }

    }
</script>

<style scoped>

</style>
