var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"8"}},[_c('div',{staticClass:"text-h6 font-weight-bold primary--text my-md-4 my-sm-2"},[_vm._v("Loan History")])]),_c('v-col',{attrs:{"sm":"12","md":"4"}},[_c('div',{staticClass:"float-right"},[_c('v-tabs',{staticClass:"tabs-container mb-3",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._l(([{name: 'All', value: null}, {name: 'Approved', value: 1}, {name: 'Rejected', value: 2}, {name: 'Pending', value: 0}]),function(item){return _c('v-tab',{key:item.name,on:{"click":function($event){_vm.filter=item.value}}},[_c('div',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.name))])])}),_c('v-tabs-slider',{attrs:{"color":"primary"}})],2)],1)])],1),_c('v-row',{},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"search","clearable":"","placeholder":"search by name, status or account","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},on:{"input":function($event){_vm.isTyping = true},"click:clear":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.startTimeFormat,"clearable":"","label":"Start","readonly":"","dense":"","outlined":"","append-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.start_time = null}}},'v-text-field',attrs,false),on))]}}],null,false,3181899352),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.endTimeFormat,"clearable":"","label":"End","readonly":"","outlined":"","dense":"","append-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.end_time = null}}},'v-text-field',attrs,false),on))]}}],null,false,4134812407),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu2 = false}},model:{value:(_vm.end_time),callback:function ($$v) {_vm.end_time=$$v},expression:"end_time"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"px-md-5 py-4",attrs:{"color":"secondary accent-4"},on:{"click":function($event){$event.preventDefault();return _vm.exportXLSX.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-download")]),_vm._v(" Download ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.loans,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.loans.indexOf(item) + 1)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("floatNairaFormat")(item.amount)))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("floatNairaFormat")(item.balance)))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("dashboardDateFormat")(item.created_at)))])]}},{key:"item.expiry_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("dashboardDateFormat")(item.expiry_date)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.balance>0? 'lime--text' : 'primary--text'},[_vm._v(_vm._s(item.balance>0? 'Pending' : 'Paid'))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[(!item.is_approved)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"error","label":"","text-color":"white"},on:{"click":function($event){return _vm.cancelLoan(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete-forever")]),_vm._v(" Delete ")],1):(item.balance>0&&item.is_approved === 1)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","label":"","text-color":"white"},on:{"click":function($event){$event.preventDefault();return _vm.rePay(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-currency-ngn")]),_vm._v(" Repay ")],1):(item.balance>0&&item.is_approved === 2)?_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":"warning"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-remove-outline")]),_vm._v(" Rejected ")],1):_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","disabled":true}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Repaid ")],1)],1)]}}],null,true)}),(_vm.loans.length)?_c('div',{staticClass:"float-right mt-3"},[_c('v-pagination',{attrs:{"length":_vm.getCount(),"total-visible":"7"},on:{"input":_vm.getLoans},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e(),_c('repay-modal',{attrs:{"show":_vm.showRepay,"loan-id":_vm.loanId},on:{"changeRepayment":_vm.changeRepayMethod,"dismiss":function($event){_vm.showRepay = false}}}),_c('debit-card-repay-modal',{attrs:{"show":_vm.showDebitPay,"loan-id":_vm.loanId},on:{"dismiss":function($event){_vm.showDebitPay = false},"onUnsavedCard":_vm.showUnsavedCard}}),_c('new-card-modal',{attrs:{"show":_vm.showCardModal,"loan-id":_vm.loanId},on:{"dismiss":function($event){_vm.showCardModal = false},"changeRepayment":_vm.showSavedCard}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary","width":"8"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }