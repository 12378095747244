<template>
    <div>
        <v-dialog
                v-model="show"
                persistent
                max-width="500px"
        >
            <v-card class="text-center pa-lg-10 pa-sm-5">
                <v-icon size="60" color="lime darken-1" class="mb-3">mdi-credit-card-multiple</v-icon>
                <div class="primary--text py-3 text-h5">
                    You are almost there!
                </div>
                <div class="mb-5">You can add more debit cards or continue with your loan application</div>
                <v-row>
                    <v-col cols="6">
                        <v-btn block outlined color="primary" class="py-5" @click.prevent="$emit('onAdd')">
                            Add More Card
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block color="primary" class="py-5" @click.prevent="submitApplication">
                            <v-progress-circular v-if="processing" width="5" color="secondary" indeterminate class="mr-2"/>
                            Apply Loan
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <confirmation-modal :show="showModal" @onConfirmation="navigateToTable"/>
    </div>

</template>

<script>
    import ConfirmationModal from "./ConfirmationModal";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    export default {
        components: {ConfirmationModal},
        props: ["show", "loanDetails"],
        data: () => ({
            showModal: false,
            processing: false,
        }),
        methods: {
            ...mapActions('loan', ['createLoan']),
            ...mapMutations('loan', ['storeLoans', 'clearLoanDetails']),
            submitApplication() {
                //initiate loans here
                const data = {
                    amount: this.loanDetails.amount,
                    balance: this.loanDetails.balance,
                    expiry_date: this.loanDetails.expiry_date,
                    originating_fee: this.loanDetails.originating_fee,
                    interest: this.loanDetails.interest,
                    description: this.loanDetails.description,
                    owner_id: this.user.id,
                    is_approved: 0,
                };
                this.processing = true;
                this.createLoan(data).then(res => {
                    if(res.status==='success') {
                        this.storeLoans(res.data.loans);
                        this.$emit('onLoanApply');
                        this.showModal = true;
                    }else {
                        this.$toast.error(res.message);
                        this.navigateToTable();
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.processing = false;
                });
            },
            navigateToTable() {
                this.showModal = false;
                this.$emit('onLoanApply');
                this.$emit('onSaveComplete');
                sessionStorage.clear();
                this.clearLoanDetails();
            }
        },
        computed: {
            //...mapGetters('loan', ['loanDetails']),
            ...mapGetters('auth', ['user']),
        },
    }
</script>

<style scoped>

</style>
