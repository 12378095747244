<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="pa-lg-10 pa-sm-5">
            <v-app-bar
                    height="30"
                    color="transparent"
                    class="pa-0"
                    flat
            >
                <v-spacer/>
                <v-app-bar-title class="secondary--text modal-title">
                    Tran<span class="primary--text">Save</span>
                </v-app-bar-title>
                <v-spacer/>
                <v-btn icon @click="$emit('dismiss')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>

            <v-text-field
                    class="mt-sm-4 mt-md-8 mb-md-6 mb-sm-3"
                    v-model="form.amount"
                    :rules="rules"
                    @keydown="form.clear()"
                    outlined
                    label="Amount"
                    placeholder="enter amount here"
            />
            <paystack-modal
                    :amount="form.amount > 0? form.amount*100 : 0.00"
                    :email="user.email"
                    :callback="onCallback"
                    :close="onClose"
                    :paystackkey="paystackkeys.public"
                    :reference="reference"
                    style="width: 100%;"
            >
                <v-btn
                        block
                        color="primary darken-1"
                        class="py-6"
                        @click.prevent="generateReference"
                >
                    <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-2" width="5"/>
                    Proceed to Card (₦{{form.amount}})
                </v-btn>
            </paystack-modal>
            <div class="text-center mt-sm-3 mt-md-5">
                <span @click.prevent="$emit('changeRepayment')" class="error--text font-italic link">repay with saved card?</span>
            </div>

        </v-card>
    </v-dialog>
</template>

<script>
    import PaystackModal from "vue-paystack";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    export default {
        components: { PaystackModal},
        props: {
            show: {
                default: false,
                required: true,
                type: Boolean,
            },
            loanId: {
                required: true,
                type: String,
            }
        },
        data: () => ({
            processing: false,
            val: Number,
            isValid: true,
            rules: [
                val => !!val || 'Amount is required',
                val => (val && val > 0) || 'Amount can not be zero',
            ],
            form: new Form({
                amount: '',
            }),
            reference: '',
        }),
        methods: {
            ...mapActions('loan', ['newCardRepayment']),
            ...mapMutations('loan', ['storeLoans']),
            onClose() {
                this.form.reset();
                console.log('pay modal closed');
            },
            onCallback(response) {
                const request = {
                    id: this.$props.loanId,
                    data: {
                        amount: this.form.amount,
                        reference: response.reference
                    }
                };
                this.processing = true;
                this.newCardRepayment(request).then(res => {
                    if (res.success) {
                        this.storeLoans(res.data.loans);
                        this.$toast.success("loan repayment of NGN"+this.form.amount+" successful");
                    }else {
                        if(res.status === 'error')
                            this.$toast.error(res.message);
                        else
                            this.$toast(res.message);
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.processing = false;
                    this.$emit('dismiss');
                })
            },
            dec2hex (dec) {
                return dec.toString(16).padStart(2, "0")
            },
            generateReference() {
                let arr = new Uint8Array((20) / 2);
                window.crypto.getRandomValues(arr);
                this.reference = Array.from(arr, this.dec2hex).join('');
                this.$emit('dismiss');
            },
        },
        computed: {
           ...mapGetters('loan', ['paystackkeys']),
           ...mapGetters('auth', ['user']),
        },
        created() {
            this.form.reset();
        }
    }
</script>

<style scoped lang="scss">
    .link:hover {
        cursor: pointer;
        text-decoration: underline;
    }
</style>