<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="text-center pa-lg-10 pa-sm-5">
            <v-icon size="60" color="lime darken-1" class="mb-3">mdi-checkbox-marked-circle-outline</v-icon>
            <div class="primary--text py-3">
                Your loan application has been submitted successfully
            </div>
            <div class="mb-5">Please wait while we credit your wallet with the loan amount.</div>
            <v-row>
                <v-col cols="12">
                    <v-btn block tile color="primary" class="py-6" @click.prevent="$emit('onConfirmation')">
                        Continue
                    </v-btn>
                </v-col>
<!--                <v-col cols="6">-->
<!--                    <v-btn block color="primary" class="py-5" @click.prevent="$router.push({name: 'Savings'})">-->
<!--                        Save Today-->
<!--                    </v-btn>-->
<!--                </v-col>-->
            </v-row>

        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: ["show"],
    }
</script>