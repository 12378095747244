<template>
    <div>
        <v-row v-if="show" class="d-flex justify-center mt-5">
            <v-col sm="12" md="6">
                <div class="text-h5 primary--text font-weight-bold mb-4">
                    Loan Details
                </div>
                <v-card style="border: 1px solid grey">
                    <div class="grey lighten-5 px-sm-5 px-md-10 pt-sm-4 pt-md-8 pb-sm-3 pb-md-6">
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="lime--text darken-1">
                                    Processed Amount
                                </div>
                                <div class="text-h5 primary--text">
                                    {{loanDetails.amount | floatNairaFormat}}
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="lime--text darken-1">
                                    Duration
                                </div>
                                <div class="text-h5 primary--text">
                                    {{loanDetails.duration}} days
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Interest
                                </div>
                                <div class="text-h5 primary--text">
                                    {{loanDetails.interest | floatNairaFormat}}
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="">
                                    Total Due
                                </div>
                                <div class="text-h5 primary--text">
                                    {{loanDetails.balance | floatNairaFormat}}
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="px-sm-5 px-md-10">
                        <div class="primary--text mb-5 mt-4 font-weight-bold">Overview</div>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Originating Fee
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="font-weight-bold primary--text">
                                    {{loanDetails.originating_fee | floatNairaFormat}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Service Charge
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="font-weight-bold primary--text">
                                    {{loanDetails.interest | floatNairaFormat}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Processed Amount
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="font-weight-bold primary--text">
                                    {{loanDetails.amount | floatNairaFormat}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Total Repayment
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="font-weight-bold primary--text">
                                    {{loanDetails.balance | floatNairaFormat}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="text-left">
                                <div class="">
                                    Due Date
                                </div>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <div class="font-weight-bold primary--text">
                                    {{loanDetails.expiry_date | dashboardDateFormat}}
                                </div>
                            </v-col>
                        </v-row>

                        <div class="d-flex d-block my-5">
                            <div class="primary--text font-weight-bold mr-4">Note</div>
                            <div>Repayment will be automatically debited from your wallet or debit card after due date.</div>
                        </div>
                        <v-form class="mb-sm-5 mb-md-10">
                            <v-row>
                                <v-col sm="6">
                                    <v-btn
                                            color="primary"
                                            block
                                            tile
                                            outlined
                                            class="py-md-7 py-sm-4"
                                            @click="showRequest = true"
                                    >
                                        Apply via Admin
                                    </v-btn>
                                </v-col>
                                <v-col sm="6">
                                    <v-btn
                                            color="primary"
                                            block
                                            tile
                                            class="py-md-7 py-sm-4"
                                            @click.prevent="submitForm"
                                    >
                                        Continue
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <div class="">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                                size="24"
                                                color="grey"
                                                v-bind="attrs"
                                                v-on="on"
                                        >mdi-alert-circle</v-icon>
                                    </template>
                                    <span>
                                        Apply for higher credit amount through the 'apply through admin'.
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-form>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <request-modal :show="showRequest" @dismiss="showRequest=false" @onSubmitRequest="gotoLoans"/>
        <cards-alert-modal :show="alertModal" :loan-details="loanDetails" @onAdd="showCardInitModal" @onLoanApply="initModals" @onSaveComplete="gotoLoans"/>
        <card-initialization
                :show="cardModal"
                @onAddCancel="initModals"
                :is-loading="loading"
        >
            <paystack
                    :amount="5000"
                    :email="user.email"
                    :callback="onCallback"
                    :close="onClose"
                    :paystackkey="paystackkeys.public"
                    :reference="reference"
            >
                <v-btn block color="primary" class="py-5" @click.prevent="generateReference">
                    <v-icon class="mr-2">mdi-credit-card</v-icon>
                    validate card
                </v-btn>
            </paystack>
        </card-initialization>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import CardsAlertModal from "./CardsAlertModal";
    import RequestModal from "./RequestModal";
    import CardInitialization from "./CardInitialization";
    import paystack from "vue-paystack";

    export default {
        props: ["show", "loanDetails"],
        components: {CardInitialization, RequestModal, CardsAlertModal, paystack },
        data: () =>({
            isValid: true,
            value: Number,
            alertModal: false,
            cardModal: false,
            showRequest: false,
            loading: false,
            reference: '',
        }),
        methods: {
            ...mapActions('loan', ['allUserPaymentCards', 'onLoanCallback']),
            submitForm() {
                !this.cards.length? this.cardModal = true : this.alertModal = true;
            },
            initModals() {
                this.alertModal = false; this.cardModal = false;
            },
            showCardInitModal() {
                this.initModals();
                this.cardModal = true;
            },
            showAlertModal() {
                this.initModals();
                this.alertModal = true;
            },
            onCallback(response) {
                const data = {id: this.user.id, reference: response.reference};
                this.onLoanCallback(data).then(res => {
                    if (res.success) {
                        console.log('callback response', res.data);
                        this.showAlertModal();
                    }
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                })
            },
            onClose() {
                console.log('pay modal closed');
            },
            gotoLoans() {
                this.$emit('onComplete');
            },
            dec2hex (dec) {
                return dec.toString(16).padStart(2, "0")
            },
            generateReference() {
                let arr = new Uint8Array((20) / 2);
                window.crypto.getRandomValues(arr);
                this.reference = Array.from(arr, this.dec2hex).join('');
                this.cardModal = false;
            }
        },
        computed: {
            ...mapGetters('loan', ['cards', 'paystackkeys']),
            ...mapGetters('auth', ['user']),
        },
        created() {
            this.allUserPaymentCards(this.user.id);
        },

    }
</script>

<style scoped>

</style>
