<template>
    <v-container class="px-5">
        <div class="px-5">
            <div class="grey--text mb-4">Hey!
                <span class="text-h5 font-weight-bold primary--text ml-3" v-text="user.name"></span>
            </div>
            <v-row no-gutters class="grey lighten-5 pa-3 pa-md-8 rounded-lg">
                <v-col cols="8" class="pa-3">
                    <div class="text-h5 lime--text darken-1 mb-5">Cash Loan</div>
                    <div class="my-2">Get a loan of up to {{maxLoanAmount | floatNairaFormat}} for your personal or<br/> business need</div>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <div class="font-weight-bold text-h6 primary--text">
                                Total Loan Granted {{ loanBalance | floatNairaFormat}}
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="pages.table!==true" tile color="primary darken-3" class="white--text py-5 px-2" @click.prevent="onApplyClick">
                                Apply Now
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="pages.table!==true" tile color="blue darken-3" class="white--text py-5 px-2" @click.prevent="onRequest">
                                Requests
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="4" class="pa-2">
                    <img src="@/static/assets/loan-icon.png" height="150px" width="auto" alt=""/>
                </v-col>
            </v-row>
            <repay-modal
                    @state="(state) => {modals.repay = state}"
                    :value="modals.repay"
                    @onSubmit="modals.repay = false"
            />
            <update-alert-modal :show="modals.update" @onCancel="modals.update=false"/>
            <terms-and-conditions :show="modals.accept" @dismiss="modals.accept=false" @onDisagree="showPage('table')"/>

            <!-- pages -->
            <loan-table :value="pages.table"/>
            <loan-calculator :show="pages.calculator" @onGetLoanDetails="fetchLoanDetails" @onSubmit="showPage('summary')" @onFailedAmount="showPage('table')"/>
            <loan-account :show="pages.account" @onSubmit="showPage('table')"/>
            <loan-summary :show="pages.summary" :loanDetails="loanDetails" @onComplete="showPage('table')"/>

            <v-overlay :value="loading">
                <div class="mb-3 primary--text">..details loading</div>
                <v-progress-circular indeterminate size="64" color="primary" width="8"></v-progress-circular>
            </v-overlay>
        </div>
    </v-container>
</template>

<script>

    import UpdateAlertModal from "./components/UpdateAlertModal";
    import ConfirmationModal from "./components/ConfirmationModal";
    import RepayModal from "./components/RepayModal";
    import LoanCalculator from "./components/LoanCalculator";
    import LoanSummary from "./components/LoanSummary";
    import LoanTable from "./components/LoanTable";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import LoanAccount from "./components/LoanAccount";
    import CardsAlertModal from "./components/CardsAlertModal";
    import TermsAndConditions from "./components/TermsAndConditions";
    export default {
        name: "index",
        components: {
            TermsAndConditions,
            CardsAlertModal,
            LoanAccount,
            LoanTable,
            LoanSummary, LoanCalculator, RepayModal, ConfirmationModal, UpdateAlertModal},
        data: () => ({
            loading: false,
            maxLoanAmount: 50000,
            loanDetails: {},
            modals: {
                accept: false,
                confirm: false,
                update: false,
                repay: false,
                request: false,
            },
            pages: {
                table: false,
                calculator: false,
                account: false,
                summary: false,
                card: false,
                update: false,
            }
        }),
        methods: {
            ...mapActions('loan', ['getKycStatus', 'fetchLoanAccount', 'fetchGuarantors', 'allUserPaymentCards']),
            ...mapMutations('loan', ['storeLoanAccount', 'storeKycStatus']),
            initialise() {
                for (const key in this.modals) {
                    if (this.modals.hasOwnProperty(key)) {
                        this.modals[key] = false;
                    }
                }
                for (const key in this.pages) {
                    if (this.pages.hasOwnProperty(key)) {
                        this.pages[key] = false;
                    }
                }
            },
            fetchLoanDetails(data) {
                this.loanDetails = data;
            },
            getLoanAccount() {
                this.fetchLoanAccount(this.user.id).then(res => {
                    this.storeLoanAccount(res.data);
                    if (!res.data.is_onboarded) {
                        this.modals.accept = true;
                    }
                }).catch(err => {
                    //this.$toast.error(err.response.data.message);
                })
            },
            fetchKyCStatus() {
                this.getKycStatus(this.user.id).then(res => {
                    this.storeKycStatus(res.data);
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                })
            },
            showPage(page) {
                this.initialise();
                this.pages[page] = true;
            },
            onApplyClick() {
                // this.getKycStatus(this.user.id);
                // if (this.kycStatus === false) {
                //     this.modals.update = true;
                // }else if(this.guarantors.length < 2 || !this.loanAccount.is_completed) {
                //     this.showPage('account');
                // }else {
                //     this.showPage('calculator');
                // }
                this.loading = true;
                this.fetchLoanAccount(this.user.id).then(res => {
                    this.storeLoanAccount(res.data);
                    if (!res.data.is_onboarded) {
                        this.modals.accept = true;
                    }else {
                        this.getKycStatus(this.user.id).then(res => {
                            this.storeKycStatus(res.data);
                            if (this.kycStatus === false) {
                                this.modals.update = true;
                            }else if(this.guarantors.length < 2 || !this.loanAccount.is_completed) {
                                this.showPage('account');
                            }else {
                                this.showPage('calculator');
                            }
                        })
                    }
                }).catch(err => {
                    //this.$toast.error(err.response.data.message);
                }).finally(() => {
                   this.loading = false;
                })
            },
            onRequest() {
                this.loading = true;
                this.getKycStatus(this.user.id).then(res => {
                    this.storeKycStatus(res.data);
                    if (this.kycStatus === false) {
                        this.modals.update = true;
                    }else if(this.guarantors.length < 2 || !this.loanAccount.is_completed) {
                        this.showPage('account');
                    }else {
                        this.$router.push({name: 'Request'});
                    }
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                })
            },
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('loan', ['kycStatus', 'loanAccount', 'loanBalance', 'guarantors']),
        },
        mounted() {
            this.showPage('table');
            this.fetchKyCStatus();
            //this.getKycStatus(this.user.id);
            this.getLoanAccount();
            this.fetchGuarantors(this.user.id);
            this.allUserPaymentCards(this.user.id);
            this.modals.accept = true;
        }

    }
</script>

<style scoped>
    .h4 {
        font-size: xx-large;
    }
</style>
