<template>
    <div v-if="show" class="mt-5">
        <div class="primary--text font-weight-bold text-h5 pt-4">Loan Account Detail</div>
        <v-stepper v-model="step" alt-labels flat tile>
            <v-stepper-header>
                <v-stepper-step step="1">
                    Basic Information
                </v-stepper-step>

                <v-stepper-step step="2">
                    Work Information
                </v-stepper-step>

                <v-stepper-step step="3">
                    Guarantors Information
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-form lazy-validation ref="form">
                    <v-stepper-content step="1">
                        <v-row class="mt-4">
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v=>!!v || 'contact name is required']"
                                        v-model="form.emergency_contact_name"
                                        outlined
                                        label="Emergency Contact Name"
                                        placeholder="enter emergency contact name"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        :rules="[v => !!v || 'contact phone is required', v => v.length <= 20 || 'cant exceed 20 characters', v => v.length >= 9 || 'cant be less than 9 characters']"
                                        v-model="form.emergency_contact_number"
                                        outlined
                                        label="Emergency Contact Phone"
                                        placeholder="enter emergency contact phone"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-select
                                        v-model="form.educational_qualification_id"
                                        :items="educationalQualifications"
                                        :rules="[v =>!!v || 'qualification required']"
                                        item-value="id"
                                        item-text="name"
                                        placeholder="select educational qualification"
                                        label="Highest Educational Qualification"
                                        outlined
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                        v-model="form.residential_status_id"
                                        :items="residentialStatuses"
                                        :rules="[v =>!!v || 'residential status required']"
                                        item-value="id"
                                        item-text="status"
                                        placeholder="select residential status"
                                        label="Residential Status"
                                        outlined
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                        v-model="form.number_of_children"
                                        type="number"
                                        outlined
                                        label="No Of Children (optional)"
                                        placeholder="enter number of children"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-btn
                                        block
                                        color="primary"
                                        class="py-7"
                                        @click.prevent="proceedToTwo"
                                >
                                    Continue
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-row class="mt-4">
                            <v-col cols="6">
                                <v-select
                                        v-model="form.employment_status_id"
                                        :items="employmentStatuses"
                                        :rules="[v =>!!v || 'employment status required']"
                                        item-value="id"
                                        item-text="status"
                                        placeholder="select employment status"
                                        label="Employment Status"
                                        outlined
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        v-model="form.company"
                                        :rules="[(v=>!!v || optionalCompany()[1] === false) || 'This field is required']"
                                        outlined
                                        :label="optionalCompany()[0]"
                                        :placeholder="optionalCompany()[0]"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                        v-model="form.job_title"
                                        outlined
                                        label="Job Title (optional)"
                                        placeholder="enter job title"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                :value="empDate"
                                                label="Date Employed"
                                                readonly
                                                :disabled="!optionalCompany()[1]"
                                                outlined
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="form.employment_date"
                                            @change="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                        v-model="form.monthly_income_id"
                                        :items="monthlyIncomes"
                                        :rules="[v =>!!v || 'monthly income required']"
                                        item-value="id"
                                        item-text="range"
                                        placeholder="select monthly income"
                                        label="Monthly Income Range"
                                        outlined
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                        v-model="form.other_information"
                                        outlined
                                        label="Tell us about yourself"
                                        placeholder="tell us about yourself"
                                />
                            </v-col>
                        </v-row>
                        <v-card-actions >
                            <v-spacer/>
                            <v-btn
                                    color="secondary"
                                    @click.prevent="step = 1"
                                    class="py-sm-5 py-md-7 px-sm-7 px-md-13 mr-5"
                            >
                                Back
                            </v-btn>
                            <v-btn
                                    color="primary"
                                    class="py-sm-5 py-md-7 px-sm-7 px-md-13"
                                    @click.prevent="proceedToThree"
                            >
                                Proceed
                            </v-btn>
                        </v-card-actions>

                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <div v-if="guarantors.length">
                            <v-row class="mb-3">
                                <v-col cols="6">
                                    <div class="text-h5 font-weight-bold">Loan Guarantors</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="float-right">
                                        <v-btn
                                                color="primary" class="py-6 px-7"
                                                @click.prevent="addModal=true"
                                        >
                                            Add Guarantor
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-data-table
                                    :headers="headers"
                                    :items="guarantors"
                                    disable-pagination
                                    :hide-default-footer="true"
                            >
                                <template v-slot:[`item.index`]="{ item }">
                                    {{ guarantors.indexOf(item) + 1 }}
                                </template>
                                <template v-slot:[`item.file_name`]="{ item }">
                                    <v-btn small color="info" @click="previewDocument(item.uploaded_file_url)">
                                        <v-icon class="white--text">mdi-download</v-icon>
                                    </v-btn>
                                </template>
                                <template v-slot:[`item.created_at`]="{ item }">
                                    <div>{{ item.created_at | dashboardDateFormat }}</div>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-avatar
                                            @click="removeGuarantor(item)"
                                            color="secondary"
                                            size="30"
                                    >
                                        <v-icon class="white--text">mdi-delete</v-icon>
                                    </v-avatar>
                                </template>
                            </v-data-table>

                            <v-card-actions class="mt-5">
                                <v-spacer/>
                                <v-btn
                                        color="secondary"
                                        @click.prevent="step = 2"
                                        class="py-sm-5 py-md-7 px-sm-7 px-md-13 mr-5"
                                >
                                    Back
                                </v-btn>
                                <v-btn
                                        color="primary"
                                        class="py-sm-5 py-md-7 px-sm-7 px-md-13"
                                        @click.prevent="submitLoanAccount"
                                >
                                    <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-3" width="5"/>
                                    Submit
                                </v-btn>
                            </v-card-actions>
                        </div>
                        <div v-else class="d-flex justify-center align-center" style="height: 400px;">
                            <v-col cols="6">
                                <div class="text-center">
                                    <div class="text-h5">Empty guarantor list!</div>
                                    <div class="my-5">Please add at least two guarantors to continue</div>
                                    <v-btn
                                            color="primary" class="py-6 px-7"
                                            @click.prevent="addModal=true"
                                    >
                                        Add Guarantor
                                    </v-btn>
                                </div>
                            </v-col>
                        </div>
                    </v-stepper-content>
                </v-form>
            </v-stepper-items>
        </v-stepper>
        <v-dialog
                v-model="addModal"
                @click:outside="addModal=false"
                persistent
                max-width="600px"
        >
            <v-card class="pa-3">
                <v-card-title class="headline white--text pa-0">
                    <v-app-bar
                            height="50"
                            color="transparent"
                            class="pa-1 mx-1 mt-0"
                            flat
                    >
                        <v-spacer/>
                        <v-app-bar-title class="text-uppercase modal-title">Add Loan Guarantor</v-app-bar-title>
                        <v-spacer/>
                        <v-btn icon @click="addModal=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>
                <!-- add guarantor -->
                <v-form ref="form" lazy-validation>
                    <div class="pa-2">
                        <v-card-text>
                            <v-row class="mt-4">
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.name"
                                            outlined
                                            dense
                                            label="Name"
                                            :rules="[v =>!!v || 'guarantor name required']"
                                            placeholder="enter name"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.phone"
                                            outlined
                                            dense
                                            label="Phone"
                                            :rules="[v =>!!v || 'guarantor phone required', v=>v.length>9 || 'phone no. is is too short', v=>v.length < 20 || 'phone no. is too long']"
                                            placeholder="enter phone number"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.email"
                                            outlined
                                            dense
                                            label="Email (optional)"
                                            placeholder="enter email"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-select
                                            v-model="guarantor.id_card_type_id"
                                            :items="idcards"
                                            :rules="[v =>!!v || 'id card type required']"
                                            item-value="id"
                                            item-text="name"
                                            placeholder="select id card type"
                                            label="ID Card Type"
                                            outlined
                                            dense
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.id_card_number"
                                            outlined
                                            dense
                                            :rules="[v =>!!v || 'id card number required']"
                                            label="ID Card Number"
                                            placeholder="enter ID card number"
                                    />
                                </v-col>
                                <v-col cols="6" class="form-input">
                                    <v-file-input
                                            v-model="guarantor.file"
                                            @change="onFileChange"
                                            label="Upload File"
                                            outlined
                                            dense
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12" class="form-input">
                                    <v-text-field
                                            v-model="guarantor.address"
                                            outlined
                                            dense
                                            label="Address"
                                            :rules="[v =>!!v || 'guarantor address required']"
                                            placeholder="enter address"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>

                            <v-btn
                                    color="primary"
                                    class="py-sm-5 py-md-6 px-sm-7 px-md-10 mr-2"
                                    @click.prevent="handleAddGuarantor"
                            >
                                Add
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        props: {
           show: {
               type: Boolean,
               default: false,
           }
        },
        data: () => ({
            step: 1,
            processing: false,
            menu1: false,
            addModal: false,
            form: new Form({
                emergency_contact_name: '',
                emergency_contact_number: '',
                number_of_children: '',
                educational_qualification_id: '',
                monthly_income_id: '',
                residential_status_id: '',
                employment_status_id: '',
                other_information: '',
                job_title: '',
                company: '',
                employment_date: null,
            }),
            guarantor: new Form({
                name: '',
                email: '',
                phone: '',
                address: '',
                id_card_type_id: '',
                id_card_number: '',
                file: {},
            }),
            headers: [
                {text: 'S/No', value: 'index', align: 'start', sortable: false},
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Phone', value: 'phone'},
                {text: 'card no.', value: 'id_card_number'},
                {text: 'Uploaded File', value: 'file_name'},
                {text: 'Date', value: 'created_at'},
                {text: 'Action', value: 'action'},
            ],

        }),
        methods: {
            ...mapActions('loan', ['allResidential', 'allIncomes', 'allQualifications', 'allEmployments', 'saveLoanAccount', 'allIdCardTypes', 'saveGuarantor', 'deleteGuarantor']),
            ...mapMutations('loan', ['storeLoanAccount']),
            submitLoanAccount() {
                this.processing = true;
                this.form.user_id = this.user.id;
                this.saveLoanAccount(this.form).then(res => {
                    this.$toast.success('loan kyc updated successfully');
                    this.storeLoanAccount(res.data);
                    this.$emit('onSubmit');
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.processing = false;
                });

            },
            previewDocument(url) {
                window.open(url, "_blank");
            },
            onFileChange(e) {
                this.guarantor.file = e;
            },
            handleAddGuarantor() {

                if(this.guarantor.name!==''&&this.guarantor.phone!==''&&this.guarantor.id_card_number!==''&& this.guarantor.file.name) {
                    const data = new FormData();
                    data.append('loan_kyc_id', this.loanAccount.id);
                    data.append('name', this.guarantor.name);
                    data.append('phone', this.guarantor.phone);
                    data.append('email', this.guarantor.email);
                    data.append('address', this.guarantor.address);
                    data.append('id_card_type_id', this.guarantor.id_card_type_id);
                    data.append('id_card_number', this.guarantor.id_card_number);
                    data.append('file', this.guarantor.file);
                    this.saveGuarantor(data);
                    this.guarantor.reset();
                }else {
                    this.$toast.error('Validation errors');
                }
               this.addModal = false;
            },
            removeGuarantor(guarantor) {
                this.deleteGuarantor(guarantor.id);
            },
            proceedToTwo() {
                let validate = this.form.emergency_contact_name!==''&&this.form.emergency_contact_number!==''&&
                    this.form.emergency_contact_number.length>=9&&this.form.emergency_contact_number.length<=20&&
                    this.form.residential_status_id!==''&&this.form.educational_qualification_id!=='';
                if(validate) {
                    this.step = 2;
                }else {
                    this.$toast.error('Errors exist on current page');
                }
            },
            proceedToThree() {
                let validate = this.form.employment_status_id!==''&& this.form.monthly_income_id!=='';
                //let optionValidate = this.form.company !==''&&this.form.employment_status_id !== 3;
                if(validate) {
                    this.step = 3;
                }else {
                    this.$toast.error('Errors exist on current page');
                }
            },
            optionalCompany() {
                let array = '';
                switch (this.form.employment_status_id) {
                    case 1: {
                        array = ['Establishment Name (required)', true];
                        break
                    }
                    case 2: {
                        array = ['Company Name (required)', true];
                        break;
                    }
                    case 3: {
                        array = ['Company Name (optional)', false];
                        break;
                    }
                    case 4: {
                        array = ['School Name (required)', true];
                        break;
                    }
                    default:
                        array = ['Company Name (required)', true];
                }
                return array;
            },
            loadDefaults() {
                this.allResidential({});
                this.allIncomes({});
                this.allQualifications();
                this.allEmployments();
            }
        },
        computed: {
            ...mapGetters('loan', ['residentialStatuses', 'employmentStatuses', 'educationalQualifications', 'monthlyIncomes', 'loanAccount', 'idcards', 'guarantors']),
            ...mapGetters('auth', ['user']),
            empDate() {
                return this.form.employment_date ? moment(this.form.employment_date).format('Do MMM [,] YYYY') : ''
            }
        },
        created() {
            this.loadDefaults();
            this.allIdCardTypes({});
            this.form.fill(this.loanAccount);
        },
    }
</script>

<style scoped lang="scss">
    .form-input {
        margin-top: -20px!important;
    }
</style>
