<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="text-center pa-lg-10 pa-sm-5">
            <v-icon size="60" color="lime darken-1" class="mb-3">mdi-alert-circle-outline</v-icon>
            <div class="primary--text py-3 text-h5">
                Attention!
            </div>
            <div class="mb-5">Please note that you will be charged {{50.00 | floatNairaFormat}} to validate your card</div>
            <v-row>
                <v-col cols="6">
                    <v-btn block outlined color="error" class="py-5" @click.prevent="$emit('onAddCancel')">
                        Cancel
                    </v-btn>
                </v-col>
                <v-col cols="6">
                   <slot></slot>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            }
        }
    }
</script>

<style scoped>

</style>