<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="text-center pa-lg-10 pa-sm-5">
            <v-icon size="60" color="lime darken-1" class="mb-3">mdi-alert-circle-outline</v-icon>
            <div class="primary--text py-3 text-h5">
                Your kyc is not updated
            </div>
            <div class="mb-5">Kindly update your account with necessary details to be eligible to access our loan offers</div>
            <v-row>
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="py-5" @click.prevent="cancelClick">
                        Cancel
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="py-5" @click.prevent="updateClick">
                        Update
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

    </v-dialog>
</template>

<script>
    export default {
        props: ["show"],

        methods: {
            cancelClick() {
                this.$emit('onCancel', false);
            },
            updateClick() {
                this.$router.push({name: 'Accounts'});
            }
        },
    }
</script>

<style scoped>

</style>
