<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="400px"
    >
        <v-card class="pa-lg-12 pa-sm-6">
            <div class="text-center mb-6">
                <div class="mb-3 text-h5 primary--text">Enter Your Transave PIN</div>
                <pin-code-input v-model="form.pin" autofocus :secure="true" :length="4"/>
            </div>
            <div class="px-md-3 px-sm-2">
                <v-btn
                        block
                        color="primary darken-1"
                        class="py-6"
                        @click.prevent="submitPinCode"
                >
                    <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-3" width="5"/>
                    Submit
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
    import PinCodeInput from 'vue-pincode-input'
    import {mapActions, mapMutations} from "vuex";
    export default {
        components: {PinCodeInput},
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            processing: {
                type: Boolean,
                default: false,
            }
        },
        data: () => ({
            form: new Form({
                pin: '',
            })
        }),
        methods: {
            submitPinCode() {
                this.$emit('onSubmit', this.form.pin);
            }
        },
        mounted() {
            this.form.reset();
            this.form.pin = '';
        }
    }
</script>

<style scoped>

</style>