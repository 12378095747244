<template>
    <div>
        <v-dialog
                v-model="show"
                persistent
                max-width="500px"
        >
            <v-card class="pa-lg-10 pa-sm-5">
                <v-app-bar
                        height="30"
                        color="transparent"
                        class="pa-0"
                        flat
                >
                    <v-spacer/>
                    <v-app-bar-title class="secondary--text modal-title">
                        Tran<span class="primary--text">Save</span>
                    </v-app-bar-title>
                    <v-spacer/>
                    <v-btn icon @click="$emit('dismiss')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-form @submit.prevent="showPinModal" v-model="isValid" lazy-validation ref="form">
                    <v-select
                            class="mt-sm-4 mt-md-8"
                            v-model="form.card_id"
                            :items="cards"
                            :rules="[v =>!!v || 'card is required']"
                            item-value="id"
                            item-text="account_number"
                            placeholder="select debit card"
                            label="Select Debit Card"
                            outlined
                            clearable
                    >
                        <template v-slot:item="{ item }">
                            <span>{{item.bank_name}}</span> -
                            <span>{{item.card_type}}</span>
                        </template>
                        <template v-slot:selection="{ attr, on, item, selected }">
                            <v-chip
                                    v-bind="attr"
                                    :input-value="selected"
                                    color="primary darken-3"
                                    class="white--text"
                                    v-on="on"
                            >
                                <v-icon left>
                                    mdi-account-circle
                                </v-icon>
                                <span>{{item.bank_name+' - '+item.card_type+' , '+item.card_bin}}</span>
                            </v-chip>
                        </template>
                    </v-select>
                    <v-text-field
                            class=""
                            v-model="form.amount"
                            :rules="rules.amount"
                            @keydown="form.clear()"
                            outlined
                            label="Amount"
                            placeholder="enter amount here"
                    />

                    <v-btn
                            block
                            color="primary darken-1"
                            class="py-6"
                            type="submit"
                    >
                        Pay Now (₦{{form.amount}})
                    </v-btn>
                    <div class="text-center mt-sm-3 mt-md-5">
                        <span @click.prevent="$emit('onUnsavedCard')" class="error--text font-italic link">use unsaved card?</span>
                    </div>
                </v-form>
            </v-card>
        </v-dialog>
        <transaction-pin-modal :show="showPin" :processing="processing" @onSubmit="repayWithCard"/>
    </div>
</template>

<script>
    import PinCodeInput from 'vue-pincode-input'
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import TransactionPinModal from "./TransactionPinModal";
    export default {
        props: ["show", "loanId"],
        components: {TransactionPinModal, PinCodeInput},
        data: () =>({
            processing: false,
            val: Number,
            isValid: true,
            rules: {
                amount: [
                    val => !!val || 'Amount is required',
                    val => (val && val > 0) || 'Amount can not be zero',
                ],
            },
            showPin: false,
            isNewCard: false,
            form: new Form({
                card_id: '',
                amount: '',
            })
        }),
        methods: {
            ...mapActions('loan', ['allUserPaymentCards', 'cardRepayment']),
            ...mapMutations('loan', ['storeLoans']),
            repayWithCard(pin) {
                if (this.$refs.form.validate()) {
                    const inputs = {
                        id: this.$props.loanId,
                        data: {
                            amount: this.form.amount,
                            card_id: this.form.card_id,
                            pin: pin,
                        }
                    };
                    this.processing = true;
                    this.cardRepayment(inputs).then(res => {
                        if(res.status === 'success') {
                            this.storeLoans(res.data.loans);
                            this.$toast.success("loan repayment of NGN"+this.form.amount+" successful");
                        }else{
                            if(res.status === 'error')
                                this.$toast.error(res.message);
                            else
                                this.$toast(res.message);
                        }
                        this.$emit('dismiss');
                        this.showPin = false;
                        this.form.reset();
                        this.form.clear();
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    }).finally(() => {
                        this.processing = false;
                        this.showPin = false;
                        this.$emit('dismiss');
                    });
                }
            },
            showPinModal() {
                this.showPin = true;
                this.$emit('dismiss');
            }
        },
        computed: {
            ...mapGetters('loan', ['cards']),
            ...mapGetters('auth', ['user'])
        },
        mounted() {
            this.allUserPaymentCards(this.user.id);
        }

    }
</script>

<style scoped lang="scss">
    .link:hover {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
