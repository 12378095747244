<template>
    <div>
        <v-dialog
                v-model="show"
                persistent
                max-width="500px"
        >
            <v-card class="pa-lg-10 pa-sm-5">
                <v-app-bar
                        height="30"
                        color="transparent"
                        class="pa-0"
                        flat
                >
                    <v-spacer/>
                    <v-app-bar-title class="secondary--text modal-title">
                        Tran<span class="primary--text">Save</span>
                    </v-app-bar-title>
                    <v-spacer/>
                    <v-btn icon @click="$emit('dismiss')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>

                <v-form @submit.prevent="onFormSubmit" v-model="isValid" lazy-validation ref="form">

                    <v-text-field
                            class="mt-sm-4 mt-md-8 mb-md-6 mb-sm-3"
                            v-model="form.amount"
                            :rules="rules"
                            @keydown="form.clear()"
                            outlined
                            label="Amount"
                            placeholder="enter amount here"
                    />

                    <v-btn
                            block
                            color="primary darken-1"
                            class="py-6"
                            type="submit"
                    >
                        <v-progress-circular v-if="processing" indeterminate color="secondary" class="mr-3" width="5"/>
                        Repay with wallet
                    </v-btn>
                    <div class="text-center mt-sm-3 mt-md-5">
                        <span @click.prevent="$emit('changeRepayment')" class="error--text font-italic link">repay with debit card?</span>
                    </div>
                </v-form>

            </v-card>
        </v-dialog>
        <transaction-pin-modal :show="showPin" :processing="processing" @onSubmit="repayWithWallet"/>
    </div>
</template>

<script>
    import TransactionPinModal from "./TransactionPinModal";
    import {mapActions, mapMutations} from "vuex";
    export default {
        components: {TransactionPinModal},
        props: ["show", "loanId"],
        data: () =>({
            val: Number,
            isValid: true,
            showPin: false,
            processing: false,
            rules: [
                val => !!val || 'Amount is required',
                val => (val && val > 0) || 'Amount can not be zero',
            ],
            form: new Form({
                amount: '',
            }),
        }),
        methods: {
            ...mapActions('loan', ['walletRepayment']),
            ...mapMutations('loan', ['storeLoans']),
            onFormSubmit() {
                if (this.$refs.form.validate()) {
                    this.showPin = true;
                    this.$emit('dismiss');
                }
            },
            repayWithWallet(pin) {
                const data = {
                    id: this.$props.loanId,
                    data: {
                        amount: this.form.amount,
                        pin: pin,
                    }
                };
                this.processing = true;
                this.walletRepayment(data).then(res => {
                    if(res.status === 'success') {
                        this.storeLoans(res.data.loans);
                        this.$toast.success("loan repayment of NGN"+this.form.amount+" successful");
                    }else{
                        if(res.status === 'error')
                            this.$toast.error(res.message);
                        else
                            this.$toast(res.message);
                    }
                    this.$emit('dismiss');
                    this.showPin = false;
                    this.form.reset();
                    this.form.clear();
                }).catch(err => {
                    this.$toast.error(err.response.data.message);
                }).finally(() => {
                    this.processing = false;
                });
            }
        },

    }
</script>

<style scoped lang="scss">
    .link:hover {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
