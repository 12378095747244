<template>
    <v-dialog
            v-model="show"
            persistent
            max-width="500px"
    >
        <v-card class="text-center pa-lg-10 pa-sm-5">
            <v-app-bar
                    height="30"
                    color="transparent"
                    class="pa-0"
                    flat
            >
                <v-spacer/>
                <v-app-bar-title class="secondary--text modal-title">
                    Tran<span class="primary--text">Save</span>
                </v-app-bar-title>
                <v-spacer/>
                <v-btn icon @click="$emit('dismiss')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>
            <v-icon size="60" color="lime darken-1" class="mb-3">mdi-alert</v-icon>
            <slot/>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            }
        },
        name: "AlertModal"
    }
</script>

<style scoped>

</style>